import React, { useState } from "react";
import SectionHeading from "../sectionHeading";
import TestimonialCard from "../testimonialCard";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
// import { courseCategoryArr } from "../../common/config";
import { CtaButton } from "../ctaButton";
import { useGetTestimonialDataQuery } from "../../api/services/master";
import { useGetSectionHeadingsQuery } from "../../api/services/home";

const Testimonials = () => {
  // const testimonialArr = [
  //   {
  //     type: "image",
  //     url: "achiever-placeholder.jpg",
  //     title: "Ankita Mishra",
  //     caption: `<p>
  //     Begin your educational voyage towards a prosperous career. Explore a
  //     variety of pecialized courses designed to empower your aspirations.
  //     Discover avenues to excel and embark on a path of lifelong learning.
  //   </p>
  //   <p>Begin your educational voyage towards a prosperous career.</p>
  //   <p>
  //     Explore a variety of pecialized courses designed to empower your
  //     aspirations.
  //   </p>`,
  //     category: courseCategoryArr[2],
  //   },
  //   {
  //     type: "image",
  //     url: "achiever-placeholder.jpg",
  //     title: "Ankita Mishra",
  //     caption: `<p>
  //     Begin your educational voyage towards a prosperous career. Explore a
  //     variety of pecialized courses designed to empower your aspirations.
  //     Discover avenues to excel and embark on a path of lifelong learning.
  //   </p>
  //   <p>Begin your educational voyage towards a prosperous career.</p>
  //   `,
  //     category: courseCategoryArr[2],
  //   },
  //   {
  //     type: "video",
  //     url: "about_us_banner.mp4",
  //     title: "Ankita Mishra",
  //     caption: `<p>
  //     Begin your educational voyage towards a prosperous career. Explore a
  //     variety of pecialized courses designed to empower your aspirations.
  //     Discover avenues to excel and embark on a path of lifelong learning.
  //   </p>
  //   <p>Begin your educational voyage towards a prosperous career.</p>
  //   <p>
  //     Explore a variety of pecialized courses designed to empower your
  //     aspirations.
  //   </p>`,
  //     category: courseCategoryArr[2],
  //   },
  // ];

  const { data } = useGetTestimonialDataQuery("home");
  const { data: sectionHeadings } = useGetSectionHeadingsQuery();

  const [swiper, setSwiper] = useState(null);

  return (
    <>
      <section
        className="section__row  section--testimonials"
        data-aos="fade-up"
      >
        <div className="main__container">
          <div className="section__contentBox">
            <SectionHeading
              customCls={"section__heading"}
              tagName={"h2"}
              title={sectionHeadings?.testimonial?.testimonial_title}
            />
          </div>
        </div>
        <div className="main__container">
          <div className="testimonial__swiper">
            <div className="testimonial__swiperContainer">
              <Swiper
                spaceBetween={16}
                navigation={{
                  prevEl: ".testimonial__swiper .swiper__nav-prev",
                  nextEl: ".testimonial__swiper .swiper__nav-next",
                }}
                autoplay={{
                  enabled: true,
                  delay: 3000,
                  pauseOnMouseEnter: true,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay, Navigation]}
                className="mySwiper__testimonial"
                onSwiper={setSwiper}
              >
                {data?.map((item, index) => (
                  <SwiperSlide key={index}>
                    <TestimonialCard
                      cardConfig={{
                        type: item?.type,
                        mediaURL:
                          item?.type === "image"
                            ? item?.image_link
                            : item?.video_link,
                        hasBaseURL: false,
                        mediaAttrib: {
                          poster: item?.type === "video" && item?.image_link,
                        },
                      }}
                      title={item?.title}
                      caption={item?.description}
                      category={item?.category}
                      rank={item?.ranker}
                      swiper={swiper}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
              <div className="swiper__nav">
                <CtaButton btnCls="swiper__nav-prev" iconCls={"edi-previous"} />
                <CtaButton btnCls="swiper__nav-next" iconCls={"edi-next"} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Testimonials;
